

import axios from 'axios';

import { API_URL } from '../../utils/constant';

export const getMembershipList = async (data) => {
    const { limit, page, keyword } = data;
    const categoryData = await axios.get(`${API_URL}/memberships/list?limit=${limit}&page=${page}&keyword=${keyword}`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
        }
    })
    const categoryList = categoryData.data;
    return categoryList;

}
;

export const updateMembershipStatus = async (id, status) => {
  try {
    // Log the request details
    console.log(`Sending request to update membership status: ID=${id}, Status=${status}`);
    
    const response = await axios.put(`${API_URL}/memberships/${id}/status`, { status }, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`
      }
    });

    // Log successful response
    console.log(`Received response for membership status update:`, response.data);

    return response.data; 
  } catch (error) {
    // Log error details
    console.error('Error updating membership status:', {
      message: error.message,
      response: error.response ? {
        status: error.response.status,
        data: error.response.data,
      } : null,
    });

    throw new Error(error.response?.data?.message || 'Failed to update membership status');
  }
};



export const addMembership = async (data) => {
    const { userId, planId, transactionId } = data;
    const categoryData = await axios.post(`${API_URL}/memberships/admin/membership/${userId}`, {
        planId,
        transactionId
    },{
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
        }
    })
    const categoryList = categoryData.data;
    return categoryList;

}

export const deleteMembership = async (id) => {
    const subCatgoryData = await axios.delete(`${API_URL}/memberships/${id}`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
        }
    })
    const subCatgory = subCatgoryData.data;
    return subCatgory;
}