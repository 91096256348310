import PropTypes from 'prop-types'; import { useState, useEffect } from 'react';

// @mui


import { TextField, Stack, Select, FormHelperText, MenuItem, CircularProgress, InputLabel, IconButton, InputAdornment, Input, Checkbox, Box, Card, Typography, Modal } from '@mui/material';
import { styled } from '@mui/material/styles';

import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify';
// utils
import { API_URL } from '../../../utils/constant';

import { uploadImage } from '../../../api/upload';
import { changePassword } from '../../../api/auth';


// components

// ----------------------------------------------------------------------
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

// ----------------------------------------------------------------------

// ShopProductCard.propTypes = {
//     product: PropTypes.object,
// };

export default function ChangePasswordForm() {

    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showNewPassword, setNewPassword] = useState(false);
    const [loading, setLoading] = useState(false);

    const [formData, setFormData] = useState({});
    const [formError, setFormError] = useState(false);
    const handleFormChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };
    const handleClick = async (event) => {
        try {
            
     
        event.preventDefault(); setFormError(false);

        if (formData.currentPassword === '' || formData.newPassword === '') {
            setFormError(true);
            return false;
        }

        const data ={
            currentPassword:formData.currentPassword,
            newPassword:formData.newPassword
        }
        await changePassword(data);
     
        return alert('Your password has been changed.')
        // onSubmit(formData)onPopUpClose
     } catch (error) {

        if(error?.response.status ===401){
            localStorage.clear();
            window.location.reload();
        }
        
        alert(error?.response?.data?.error || 'Something went wrong')

            return false
        }
    };

    // console.log('addProduct', props);
    // const { open } = props;

    useEffect(() => {
    }, [])
    return (

        <Box sx={style} >
            <h3 >       Change Password     </h3>

            <form id='loginForm' onSubmit={handleClick} >

                <Stack spacing={3}>
                    <TextField
                        name="currentPassword"
                        label="Current Password"
                        onChange={handleFormChange}
                        type={showCurrentPassword ? 'text' : 'password'}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={() => setShowCurrentPassword(!showCurrentPassword)} edge="end">
                                        <Iconify icon={showCurrentPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
                    <TextField
                        name="newPassword"
                        label="New Password"
                        onChange={handleFormChange}

                        type={showNewPassword ? 'text' : 'password'}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={() => setNewPassword(!showNewPassword)} edge="end">
                                        <Iconify icon={showNewPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />
          


                    <Stack alignItems="center" spacing={2}>
                        {false ? (
                            <Stack alignItems="center" justifyContent="center">
                                <CircularProgress />
                            </Stack>
                        ) : (
                            <LoadingButton fullWidth size="large" type="submit" variant="contained">
                                Change Password                            </LoadingButton>
                        )}
                    </Stack>


                </Stack>
            </form>
        </Box>
    );
}
