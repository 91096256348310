import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';

// @mui

import {
  Link,
  Stack,
  Select,
  FormHelperText,
  MenuItem,
  CircularProgress,
  InputLabel,
  IconButton,
  InputAdornment,
  TextField,
  Input,
  Checkbox,
  Box,
  Card,
  Typography,
  Modal,
} from '@mui/material';
import { styled } from '@mui/material/styles';

// utils
import { LoadingButton } from '@mui/lab';
import { API_URL, IMAGE_URL } from '../../../utils/constant';

import { uploadImage } from '../../../api/upload';

import Iconify from '../../../components/iconify';
import { getCategoryList } from '../../../api/category';

// components

// ----------------------------------------------------------------------
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  overflow: 'auto',
  maxHeight: '100%',
};

// ----------------------------------------------------------------------

// ShopProductCard.propTypes = {
//     product: PropTypes.object,
// };

export default function AddSubCategory({ open, onPopUpClose, loading, onSubmit, initialValues }) {
  const [formData, setFormData] = useState(() => initialValues);
  const [selectedFile, setSelectedFile] = useState(null);

  const [selectedFileSrc, setSelectedFileSrc] = useState(null);
  const [categoryList, setCategoryList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [formError, setFormError] = useState(false);
  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const handleClick = async (event) => {
    event.preventDefault();
    setFormError(false);

    if (formData.name === '') {
      setFormError(true);
      return false;
    }
    if (formData.description === '') {
      formData.description = 'null';
    }
    if (selectedFile) {
      const url = await uploadImage('category', selectedFile);
      formData.imageUrl = `${IMAGE_URL}/${url.data}`;
    }
    setSelectedFileSrc(null);
    onSubmit(formData);
    return true;
  };
  const handleImageUpload = (event) => {
    event.preventDefault();
    setFormError(false);
    const file = event.target.files[0];
    setSelectedFile(file);
    const reader = new FileReader();

    reader.onload = () => {
      const base64Image = reader.result;
      // const name = 'imageUrl'
      // Use the base64Image string as needed
      setSelectedFileSrc(base64Image);
      // setFormData((prevFormData) => ({
      //     ...prevFormData,
      //     [name]: base64Image,
      // }));
    };
    reader.readAsDataURL(file);
  };

  // const { open } = props;
  const handleClose = () => {
    onPopUpClose();
  };

  const getCategoryListData = async () => {
    try {
      const data = {
        page: 1,
        limit: 100,
        keyword: '',
      };
      const categoryRes = await getCategoryList(data);
      const { categoryList, count } = categoryRes.data;

      setCategoryList(categoryList);
    } catch (error) {
      if (error?.response.status === 401) {
        localStorage.clear();
        window.location.reload();
      }
      alert(error?.response?.data?.error || 'Something went wrong');
    }
  };
  useEffect(() => {
    setFormData(initialValues);
    getCategoryListData();
  }, [initialValues]);
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <h2 style={{ marginLeft: '10px' }}>Add Sub Category</h2>
          <IconButton onClick={handleClose}>X</IconButton>
        </Stack>
        <form id="loginForm" onSubmit={handleClick}>
          <Stack spacing={3}>
            <TextField
              name="name"
              label="Sub Category Name"
              value={formData.name}
              onChange={handleFormChange}
              required
            />
            {/* <TextField
              name="description"
              label="Description"
              value={formData.description}
              onChange={handleFormChange}
            /> */}
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              name="categoryId"
              value={formData.categoryId}
              label="Age"
              onChange={(event) => {
                handleFormChange(event);
              }}
            >
              <MenuItem value={0}>--- Select Category ---</MenuItem>
              {categoryList
                .slice()
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((item, index) => (
                  <MenuItem key={index} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
            </Select>
            <TextField type="file" name="imageUrl" accept="image/jpeg, image/png" onChange={handleImageUpload} />
            <FormHelperText dark>image must 500x500</FormHelperText>
            <img height={100} width={100} src={selectedFileSrc || formData.imageUrl} alt="" />
            {formError && <FormHelperText error>This field is required.</FormHelperText>}
            {formError && <FormHelperText error>This field is required.</FormHelperText>}
            <Stack alignItems="center" spacing={2}>
              {loading ? (
                <Stack alignItems="center" justifyContent="center">
                  <CircularProgress />
                </Stack>
              ) : (
                <LoadingButton fullWidth size="large" type="submit" variant="contained">
                  {formData.id !== '' ? 'Update Sub Category' : 'Add Sub Category'}
                </LoadingButton>
              )}
            </Stack>
          </Stack>
        </form>
      </Box>
    </Modal>
  );
}
