import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import axios from 'axios';

// @mui
import {
    Card,
    Table,
    Stack,
    Paper,
    Avatar,
    Button,
    Popover,
    Checkbox,
    TableRow,
    MenuItem,
    TableBody,
    TableCell,
    Container,
    Typography,
    IconButton,
    TableContainer,
    TablePagination,
} from '@mui/material';

// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import Footer from './Footer';
// sections

import { getBrandList } from '../api/brand';
import { AddPlan, CategoryListHead, CategoryListToolBar } from '../sections/@dashboard/plan';
import AddNotification from '../sections/@dashboard/notification/AddNotification';


// mock
import USERLIST from '../_mock/user';
import { category } from '../_mock/category';
import { getPlanList, updatePlan, createPlan, deletePlan } from '../api/plan';
// import { API_URL } from '../../utils/constant';
import { API_URL } from '../utils/constant';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'id', label: 'Plan ID', alignRight: false },
    { id: 'name', label: 'Name', alignRight: false },
    { id: 'expiresIn', label: 'Validity (days)', alignRight: false },
    { id: 'price', label: 'Price', alignRight: false },

    { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}
const initialValues = {
    id: '',
    name: '',
    expiresIn: '',
};
export default function SendNotification() {
    const [open, setOpen] = useState(null);
    const [openAddPopUp, setAddPopUp] = useState(false);
    const [isAddProductLoading, setIsAddProductLoading] = useState(false);

    const [total, setTotal] = useState(0);
    const [categoryList, setCategoryList] = useState([]);
    const [page, setPage] = useState(0);

    const [order, setOrder] = useState('asc');

    const [selected, setSelected] = useState([]);

    const [orderBy, setOrderBy] = useState('name');

    const [filterName, setFilterName] = useState('');

    const [rowsPerPage, setRowsPerPage] = useState(5);

    const [categoryToBeUpdated, setCategoryToBeUpdated] = useState({
        id: '',
        name: '',
        expiresIn: '',
        price: '',
    });


    const [fmctoken, setFmctoken] = useState([]);



    const handleOpenMenu = (event, id) => {
        const category = categoryList.find((brand) => brand.id === id);

        setCategoryToBeUpdated(category);
        setOpen(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setOpen(null);
    };

    // --------------------------fetch all fcm token----------------------
    const limit = 9999999;
    const perpage = 1;
    const keyword = '';
    const fetchFcmToken = async () => {
        const response = await axios.get(`${API_URL}/users/list?limit=${limit}&page=${perpage}&keyword=${keyword}`, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        })
        console.log("token data", response.data);

        const { userList } = response.data.data;
        const fcmTokens = userList.map(user => user.fcmToken);

        setFmctoken(fcmTokens);
    }






    const handleSubmit = async (data) => {

        const url = 'https://fcm.googleapis.com/fcm/send';
        const authToken = 'AAAAFHnez5o:APA91bFhRR78Wb0sq7fcBhajI6OyOd_lXC6Z04sFqwQH8njSiPcZX34F3mLAcJs9FnfxzAyUOnqkbkoL-s6g1SEPLzhSPR17TyDt8dnHsaL6Z5KHGh_xVfjlp23g6HNsh_dop5vVT6dn';

        try {
            const response = await axios.post(url, {

                registration_ids: fmctoken,
                notification: {
                    title: data.title,
                    body: data.subtitle
                }
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `key=${authToken}`
                }
            });

            console.log(response.data);
            alert('Notification sent successfully!');
            setAddPopUp(false);
            console.log(data, "submitted data");
        } catch (error) {
            console.error(error);
            alert(error?.response?.data?.error || 'Something went wrong!');

        }

    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = USERLIST.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    const handleChangePage = async (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = async (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const handleFilterByName = (event) => {
        setPage(0);
        setFilterName(event.target.value);
    };
    const handleDelete = async (event) => {
        try {
            if (categoryToBeUpdated.id !== '') {
                await deletePlan(categoryToBeUpdated.id);
            }
            await getCategoryListData();
            setOpen(null);
        } catch (error) {
            if (error?.response.status === 401) {
                localStorage.clear();
                window.location.reload();
            }
            alert(error?.response?.data?.error || 'Something went wrong');
        }
    };
    const getCategoryListData = async () => {
        try {
            const data = {
                page: page + 1,
                limit: rowsPerPage,
                keyword: filterName,
            };
            const categoryRes = await getPlanList(data);
            const { planList, count } = categoryRes.data;
            setTotal(count);
            setCategoryList(planList);
        } catch (error) {
            if (error?.response.status === 401) {
                localStorage.clear();
                window.location.reload();
            }
            alert(error?.response?.data?.error || 'Something went wrong');
        }
    };
    useEffect(() => {
        getCategoryListData();
        fetchFcmToken()
    }, [page, rowsPerPage, filterName]);

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

    // const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

    const isNotFound = !categoryList.length && !!filterName;

    return (
        <>
            <Helmet>
                <title>Notification</title>
            </Helmet>

            <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                    <Typography variant="h4" gutterBottom>
                        Notification
                    </Typography>
                    <Button
                        variant="contained"
                        onClick={() => {
                            setCategoryToBeUpdated(initialValues);
                            setAddPopUp(true);
                        }}
                        startIcon={<Iconify icon="eva:plus-fill" />}
                    >
                        Send Notification
                    </Button>
                </Stack>


            </Container>

            {/* <AddPlan
                open={openAddPopUp}
                onPopUpClose={() => {
                    setCategoryToBeUpdated(initialValues);
                    setAddPopUp(false);
                }}
                loading={isAddProductLoading}
                onSubmit={handleSubmit}
                initialValues={categoryToBeUpdated}
            /> */}
            <AddNotification
                open={openAddPopUp}
                onPopUpClose={() => {

                    setAddPopUp(false);
                }}
                loading={isAddProductLoading}
                onSubmit={handleSubmit}

            />
            <Popover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleCloseMenu}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                    sx: {
                        p: 1,
                        width: 140,
                        '& .MuiMenuItem-root': {
                            px: 1,
                            typography: 'body2',
                            borderRadius: 0.75,
                        },
                    },
                }}
            >
                <MenuItem onClick={() => setAddPopUp(true)}>
                    <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                    Edit
                </MenuItem>

                <MenuItem sx={{ color: 'error.main' }} onClick={handleDelete}>
                    <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                    Delete
                </MenuItem>
            </Popover>
            <Container sx={{ mt: 10 }}>
                <Footer />
            </Container>
        </>
    );
}
