import PropTypes from 'prop-types'; import { useState, useEffect } from 'react';

// @mui


import { Link, Stack, Select, FormHelperText, MenuItem, CircularProgress, InputLabel, IconButton, InputAdornment, TextField, Input, Checkbox, Box, Card, Typography, Modal } from '@mui/material';
import { styled } from '@mui/material/styles';

// utils
import { LoadingButton } from '@mui/lab';
import { API_URL } from '../../../utils/constant';

import { uploadImage } from '../../../api/upload';
import { getPlanList } from '../../../api/plan';
import { getUsersListForDropDown } from '../../../api/users';


// components

// ----------------------------------------------------------------------
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflow: 'auto',
    maxHeight: '100%',
};

// ----------------------------------------------------------------------

// ShopProductCard.propTypes = {
//     product: PropTypes.object,
// };
const intialError={
    isValid:false,
    errors:{}
}
export default function AddMembership({ open, onPopUpClose, loading, onSubmit, initialValues }) {
    const [formData, setFormData] = useState(() => initialValues);
    const [selectedFile, setSelectedFile] = useState(null);
    const [planList, setPlanList] = useState([]);
    const [userList, setUserList] = useState([]);

    const [selectedFileSrc, setSelectedFileSrc] = useState(null);
    const [searchedKey, setSearchedKey] = useState(0);
    const [formError, setFormError] = useState({
        isValid:false,
        errors:{}
    });


    const onblurEffect=(event)=>{
        validationFormData();

    }
    const handleFormChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
         };

         const handleSearchKeyChange =async  (event) => {
            setSearchedKey(event.target.value);
        

            await getUserList(event.target.value)

         }
    const validationFormData= async ()=>{
        if (formData.transactionId ==='' || formData.transactionId.length <3 ) {
            
            const transactionId= 'transactionId'
            setFormError((prevFormData) => ({
                isValid:false,
                errors:{
                    ...prevFormData.errors,
                    [transactionId]: 'transactionId is not correct'
                }
            }));
            return false;
        }
        setFormError({
            isValid:true,
            errors:{

            }
        })
return true
    }
    const handleClick = async (event) => {
        event.preventDefault();
        validationFormData();

        if (selectedFile) {
            const url = await uploadImage('category', selectedFile)
            formData.imageUrl = url.data
        }
        setSelectedFileSrc(null)
        onSubmit(formData)
        return true;
    };
    const handleImageUpload = (event) => {
        event.preventDefault();
        const file = event.target.files[0];
        setSelectedFile(file);
        const reader = new FileReader();

        reader.onload = () => {
            const base64Image = reader.result;
            // const name = 'imageUrl'
            // Use the base64Image string as needed
    
            setSelectedFileSrc(base64Image);
            // setFormData((prevFormData) => ({
            //     ...prevFormData,
            //     [name]: base64Image,
            // }));
        };
        reader.readAsDataURL(file);


    };


    // const { open } = props;
    const handleClose = () => {
        onPopUpClose()
    }
    const getCategoryListData = async () => {
        try {
            const data = {
                page: 1,
                limit: 100,
                keyword: ''
            }
            const categoryRes = await getPlanList(data);
            const { planList, count } = categoryRes.data;

            setPlanList(planList)
        } catch (error) {
            if(error?.response.status ===401){
                localStorage.clear();
                window.location.reload();
            }
            alert(error?.response?.data?.error || 'Something went wrong')

        }
    };
    const getUserList = async (keyword='') => {
        try {
    

            const categoryRes = await getUsersListForDropDown(keyword);
            const { userList, count } = categoryRes.data;

            setUserList(userList)
        } catch (error) {
            if(error?.response.status ===401){
                localStorage.clear();
                window.location.reload();
            }
            alert(error?.response?.data?.error || 'Something went wrong')

        }
    };
    useEffect(() => {
        setFormData(initialValues)
        getCategoryListData()
        getUserList()
    }, [initialValues])
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"


        >
            <Box sx={style} >
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <h2 style={{ marginLeft: '10px' }}>Add Membership</h2>
                    <IconButton onClick={handleClose}>
                        X
                    </IconButton>
                </Stack>
                <form id='loginForm' onSubmit={handleClick} >

                    <Stack spacing={3}>
                    <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            name='userId'
                            value={formData.userId}
                            label="Age"
                            onChange={(event) => { handleFormChange(event) }}
                        >

{/* <TextField placeholder='--- Select User ---' label="Transaction Id" required /> */}
<TextField  value={searchedKey} onChange={ handleSearchKeyChange} fullWidth/>
  
                      
                            <MenuItem value={0}>--- Select User ---</MenuItem>
                            {userList.slice().reverse().map((item, index) => (
                                <MenuItem key={index} value={item.id}>{item.phone}</MenuItem>
                            ))
                            }
                        </Select>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            name='planId'
                            value={formData.planId}
                            label="Age"
                            onChange={(event) => { handleFormChange(event) }}
                        >
                            
                            <MenuItem value={0}>--- Select Plan ---</MenuItem>
                            {planList.map((item, index) => (
                                <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
                            ))
                            }
                        </Select>
                        <TextField name="transactionId" label="Transaction Id" value={formData.name} onChange={handleFormChange} onBlur={onblurEffect} required />
                      
                 
                

                        <Stack alignItems="center" spacing={2}>
                            {loading ? (
                                <Stack alignItems="center" justifyContent="center">
                                    <CircularProgress />
                                </Stack>
                            ) : (
                                <LoadingButton disabled={!formError.isValid} fullWidth size="large" type="submit" variant="contained">
                                    {formData.id !== '' ? 'Update Membership' : 'Add Membership'}
                                </LoadingButton>
                            )}
                        </Stack>


                    </Stack>
                </form>
            </Box>
        </Modal>
    );
}
