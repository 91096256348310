import axios from 'axios';

import { API_URL } from '../../utils/constant';

export const profile = async () => {

  return {

    displayName: 'Admin',
    email: 'admin@gmail.com',
    photoURL: '/assets/images/avatars/avatar_default.jpg',
  }
}
export const login = async (data) => {
  const loginData = await axios.post(`${API_URL}/admin/login`, data);

  return loginData.data;
}

export const changePassword = async (data) => {
  const response = await axios.post(`${API_URL}/admin/change-password`, data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`
    }
  })
  const result = response.data;
  return result;

}