import axios from 'axios';

import { API_URL } from '../../utils/constant';

export const createCategory = async (data) => {
  const categoryData = await axios.post(`${API_URL}/category-fip`, data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });
  const category = categoryData.data;
  return category;
};
export const updateCategory = async (id, data) => {
  const categoryData = await axios.put(`${API_URL}/category-fip/${id}`, data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });
  const category = categoryData.data;
  return category;
};
export const deleteCategoy = async (id) => {
  const categoryData = await axios.delete(`${API_URL}/category-fip/${id}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });
  const category = categoryData.data;
  return category;
};
export const getCategoryById = async (id) => {
  const categoryData = await axios.get(`${API_URL}/category-fip/${id}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });
  const category = categoryData.data;
  return category;
};
export const getCategoryList = async (data) => {
  const { limit, page, keyword } = data;
  const categoryData = await axios.get(`${API_URL}/category-fip/list?limit=${limit}&page=${page}&keyword=${keyword}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });
  const categoryList = categoryData.data;
  return categoryList;
};
