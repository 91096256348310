

import axios from 'axios';

import { API_URL } from '../../utils/constant';

export const getDataForDashboard = async () => {
    const categoryData = await axios.get(`${API_URL}/admin/dashboard`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
        }
    })
    const categoryList = categoryData.data;
    return categoryList;

}