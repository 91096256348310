import axios from 'axios';

import { API_URL } from '../../utils/constant';


export const viewAllNotification = async () => {
  const res = await axios.post(`${API_URL}/notification/view-notification`,null,{
    headers:{
      Authorization: `Bearer ${localStorage.getItem('token')}`
    }
  });

  return res.data;
}

export const notificationList = async () => {
  
  const response = await axios.get(`${API_URL}/notification/list`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`
    }
  })
  const result = response.data;
  return result;

}