import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';
import moment from 'moment';

// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
} from '@mui/material';
import { API_URL } from '../utils/constant';
import Footer from './Footer';


// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { getBrandList } from '../api/brand';
// import { AddCategory, CategoryListHead, CategoryListToolBar } from '../sections/@dashboard/category';
import { UserListHead, UserListToolbar, AddUser } from '../sections/@dashboard/user';

// mock
import USERLIST from '../_mock/user';
import { category } from '../_mock/category';
import { blockUser, deleteUsers, getUsersList, updateUser } from '../api/users';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'id', label: 'User ID', alignRight: false },
  { id: 'createdAt', label: 'Created At', alignRight: false },
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'phone', label: 'Phone', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'userType', label: 'user type', alignRight: false },
  { id: 'isBlocked', label: 'Block Status', alignRight: false },
  { id: 'imageurl', label: 'Image', alignRight: false },

  { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}
const initialValues = {
  id: '',
  name: '',
  phone: '',
  email: '',
  userType: '',
  imageUrl: '',
  isBlocked: 0,
};
export default function UserPage() {
  const [open, setOpen] = useState(null);
  const [openAddPopUp, setAddPopUp] = useState(false);
  const [isAddProductLoading, setIsAddProductLoading] = useState(false);

  const [total, setTotal] = useState(0);
  const [categoryList, setCategoryList] = useState([]);
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [categoryToBeUpdated, setCategoryToBeUpdated] = useState({
    id: '',
    name: '',
    phone: '',
    email: '',
    userType: '',
    imageUrl: '',
    isBlocked: 0,
  });

  const handleOpenMenu = (event, id) => {
    const category = categoryList.find((brand) => brand.id === id);
    setCategoryToBeUpdated(category);
    setOpen(event.currentTarget);
  };
  const handleSubmit = async (data) => {
    try {
      setIsAddProductLoading(true);
      if (data.id !== '') {
        await updateUser(data.id, data);
        // const index = category.categoryList.findIndex(category => category.id === data.id)
        // category.categoryList[index] = data;
      } else {
        delete data.id;
      }
      setCategoryToBeUpdated({
        id: '',
        name: '',
        phone: '',
        email: '',
        userType: '',
        imageUrl: '',
        isBlocked: 0,
      });
      await getCategoryListData();
      setIsAddProductLoading(false);
      setAddPopUp(false);
      setOpen(null);
    } catch (error) {
      setIsAddProductLoading(false);
      setCategoryToBeUpdated({
        id: '',
        name: '',
        phone: '',
        email: '',
        userType: '',
        imageUrl: '',
        isBlocked: 0,
      });
      setAddPopUp(false);
      setOpen(null);
      if (error?.response.status === 401) {
        localStorage.clear();
        window.location.reload();
      }

      alert(error?.response?.data?.error || 'Something went wrong');
    }
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = async (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = async (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };
  const handleDelete = async (event) => {
    if (categoryToBeUpdated.id !== '') {
      await deleteUsers(categoryToBeUpdated.id);
    }
    await getCategoryListData();
    setOpen(null);
  };
  const handleBlockUser = async (event) => {
    try {
      if (categoryToBeUpdated.id !== '') {
        await blockUser(categoryToBeUpdated.id);

        const alertMessage = categoryToBeUpdated.isBlocked
          ? 'User has been unblocked successfully!'
          : 'User has been blocked successfully!';
        setTimeout(() => {
          alert(alertMessage);
        }, 500);
      }
      await getCategoryListData();
      setOpen(null);
    } catch (error) {
      setOpen(null);
      if (error?.response.status === 401) {
        localStorage.clear();
        window.location.reload();
      }
      alert(error?.response?.data?.error || 'Something went wrong');
    }
  };
  const getCategoryListData = async () => {
    try {
      const data = {
        page: page + 1,
        limit: rowsPerPage,
        keyword: filterName,
      };
      const categoryRes = await getUsersList(data);
      const { userList, count } = categoryRes.data;
            console.log('Fetched productList:', userList); // Log fetched productList
      console.log('Total count:', count); // Log total count

      setTotal(count);
      setCategoryList(userList);
    } catch (error) {
      if (error?.response.status === 401) {
        localStorage.clear();
        window.location.reload();
      }
      alert(error?.response?.data?.error || 'Something went wrong');
    }
  };
  useEffect(() => {
    getCategoryListData();
  }, [page, rowsPerPage, filterName]);

  // const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  // const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

  const isNotFound = !categoryList.length && !!filterName;

  return (
    <>
      <Helmet>
        <title> Users </title>
      </Helmet>

      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Users
          </Typography>
          {/* <Button variant="contained" onClick={() => { setCategoryToBeUpdated(initialValues); setAddPopUp(true) }} startIcon={<Iconify icon="eva:plus-fill" />}>
                        Add Category
                    </Button> */}
        </Stack>

        <Card>
          <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={total}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {categoryList.map((row) => {
                    const { id, name, phone, imageUrl, email, userType, isBlocked, createdAt } = row;
                    const date = moment(createdAt).format('DD/MM/YYYY');
                    const selectedUser = selected.indexOf(name) !== -1;

                    return (
                      <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                        {/* <TableCell padding="checkbox">
                                                    <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, name)} />
                                                </TableCell> */}
                        <TableCell align="left">
                          <Typography variant="subtitle2" noWrap>
                            {id}
                          </Typography>
                        </TableCell>

                        <TableCell align="left">
                          <Typography variant="subtitle2" noWrap>
                            {date}
                          </Typography>
                        </TableCell>
                        <TableCell align="left">
                          <Typography variant="subtitle2" noWrap>
                            {name}
                          </Typography>
                        </TableCell>

                        <TableCell align="left">{phone}</TableCell>
                        <TableCell align="left">{email}</TableCell>
                        <TableCell align="left">{userType}</TableCell>
                        <TableCell align="left">{isBlocked ? 'Yes' : 'No'}</TableCell>

                        <TableCell align="left">
                          <Stack direction="row" alignItems="center" spacing={2}>
                            <Avatar alt={name} src={`http://www.dieselduniya.com/backend/public/${imageUrl}`} />
                            {/* <img src=`https://${imageUrl}` alt="user_image" /> */}
                          </Stack>
                        </TableCell>

                        <TableCell align="left">
                          <IconButton
                            size="large"
                            color="inherit"
                            onClick={(event) => {
                              handleOpenMenu(event, id);
                            }}
                          >
                            <Iconify icon={'eva:more-vertical-fill'} />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 20, 30, 50]}
            component="div"
            count={total}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      <AddUser
        open={openAddPopUp}
        onPopUpClose={() => setAddPopUp(false)}
        loading={isAddProductLoading}
        onSubmit={handleSubmit}
        initialValues={categoryToBeUpdated}
      />
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem onClick={() => setAddPopUp(true)}>
          <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
          Edit
        </MenuItem>

        {/* <MenuItem sx={{ color: 'error.main' }} onClick={handleDelete}>
                    <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                    Delete
                </MenuItem> */}
        {/* <MenuItem sx={{ color: 'error.main' }} onClick={handleBlockUser}>
          <Iconify icon={'material-symbols:block'} sx={{ mr: 2 }} />
          Block User
        </MenuItem> */}

        {categoryToBeUpdated.isBlocked ? (
          <MenuItem sx={{ color: 'success.main' }} onClick={handleBlockUser}>
            <Iconify icon={'material-symbols:check'} sx={{ mr: 2 }} />
            Unblock User
          </MenuItem>
        ) : (
          <MenuItem sx={{ color: 'error.main' }} onClick={handleBlockUser}>
            <Iconify icon={'material-symbols:block'} sx={{ mr: 2 }} />
            Block User
          </MenuItem>
        )}
      </Popover>

      <Container sx={{ mt: 10 }}>
        <Footer />
      </Container>
    </>
  );
}
