import React from 'react'
import { Link, Container, Typography, Divider, Stack, Button } from '@mui/material';
import BottomNavigation from '@mui/material/BottomNavigation';


function Footer() {
    return (
        <>
            <BottomNavigation style={{ backgroundColor: 'transparent' }}>


                <Typography variant="h6" gutterBottom>
                    @Powered by Yellowbit Labs
                </Typography>
            </BottomNavigation>

        </>
    )
}

export default Footer;