import React from 'react'
// import '../../css/home.css'

import { Link } from 'react-router-dom';



const HomePageLayout = () => {
    return (

        <>
            {/* 
            <header className="main-header" id="home" style={{ backgroundImage: 'url("https://i.imgur.com/kAzkNuE.png")' }}>
                <div className="overlay" />
                <div className="container">
                    <div className="row">
                        <div className="col-md-11 col-md-offset-1">

                            <div className="logo"> <a href style={{ color: 'white', fontSize: 24 }}> <strong> DieselDuniya </strong> a <strong> Yellowbit Labs </strong> Product </a></div>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-5 col-md-offset-1">
                            <div className="head-hero">

                                <h1 className="slideInDown animated">We are <span className="highlight">Launching</span> soon</h1>


                                <div className="subscribe">

                                    <div className="slide-left slideInLeft animated sub-form" data-plugin-options="{&quot;reverse&quot;:true}">

                                        <div className="input-group">
                                            <input type="email" className="form-control" placeholder="Email Address" required name="EMAIL" />
                                            <span className="input-group-btn">
                                                <button type="submit" className="btn btn-default button" id="mc-subscribe" value="Subscribe" name="subscribe">Request Invite</button>
                                            </span>
                                        </div>

                                        <p id="mc-notification" />
                                    </div>

                                </div>

                            </div>
                        </div>
                        <div className="col-md-5 col-md-offset-1 visible-md visible-lg over-hide"> <img src="/img/iphone-large-screen.png" alt="AppImage" className="slide-right slideInRight animated hero-img" data-plugin-options="{&quot;reverse&quot;:true, &quot;offset&quot;:-100}" /> </div>
                    </div>
                </div>
            </header> */}




            {/* 
            <div style={{ backgroundImage: 'url("/img/cooming soon.png")' }}>

            </div> */}







            <header className="main-header" id="home" style={{ backgroundImage: 'url("https://i.imgur.com/kAzkNuE.png")', height: '100vh' }}>
                <div className="overlay" />
                <div className="container" style={{ padding: '300px 0px 300px 0px', marginLeft: '30%' }}>

                    <div className="row">
                        <div className="col-md-5 col-md-offset-1">
                            <div className="head-hero">

                                <h1>We are Comming soon...</h1>


                                <div className="subscribe">

                                    <div className="slide-left slideInLeft animated sub-form" data-plugin-options="{&quot;reverse&quot;:true}">


                                        <p id="mc-notification" />
                                    </div>

                                </div>

                            </div>
                        </div>

                    </div>



                    <div className="row">
                        <div className="col-md-11 col-md-offset-1">

                            <div className="logo"> <a href style={{ color: 'black', fontSize: 24 }}> <strong> DieselDuniya </strong>
                                <br /> A Products of <strong> Yellowbit Labs </strong>  </a></div>

                        </div>
                    </div>
                </div>
            </header>






        </>
    )
}

export default HomePageLayout