import { Navigate, useRoutes, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import BlogPage from './pages/BlogPage';
import UserPage from './pages/UserPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import ProductsPage from './pages/ProductsPage';
import DashboardAppPage from './pages/DashboardAppPage';
import Products from './pages/Products';
import Category from './pages/Category';
import Brand from './pages/Brand';
import SubCategory from './pages/SubCategory';
import Plan from './pages/Plan';
import ChangePassword from './pages/ChangePassword';
import Memberships from './pages/Membership';
import CategoryMSI from './pages/Category-MSI';
import ProductMSI from './pages/Products-MSI';
import ProductsFip from './pages/ProductsFip';
import CategoryFip from './pages/CategoryFip';
import SubCategoryFip from './pages/SubCategoryFip';
import SendNotification from './pages/SendNotification';
import HomePageLayout from './pages/home/home';
// ----------------------------------------------------------------------

export default function Router() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  useEffect(() => {
    setIsAuthenticated(Boolean(localStorage.getItem('token'))); // Replace with your authentication logic
  }, [localStorage.getItem('token')]);

  const routes = useRoutes([
    {
      path: '/',
      element: <HomePageLayout />,
      children: [{ element: <HomePageLayout />, index: true }],
    },
    {
      // path: '/dashboard',
      path: 'admin',
      element: isAuthenticated ? <DashboardLayout /> : <Navigate to="/admin/login" />,

      children: [
        // { element: <DashboardAppPage /> },
        // { element: <Navigate to="/" /> },
        // { path: '/', element: < HomePageLayout /> },
        // { path: '/', element: isAuthenticated ? <DashboardLayout /> : <Navigate to="/admin/login" /> }

        { element: <Navigate to="/admin/dashboard/app" />, index: true },
        { path: 'dashboard/app', element: <DashboardAppPage /> },
        { path: 'dashboard/user', element: <UserPage /> },
        { path: 'dashboard/product', element: <Products /> },
        { path: 'dashboard/category', element: <Category /> },
        { path: 'dashboard/brand', element: <Brand /> },
        { path: 'dashboard/products', element: <ProductsPage /> },
        { path: 'dashboard/blog', element: <BlogPage /> },
        { path: 'dashboard/subCategory', element: <SubCategory /> },
        { path: 'dashboard/plans', element: <Plan /> },
        { path: 'dashboard/membership', element: <Memberships /> },
        { path: 'dashboard/changePassword', element: <ChangePassword /> },
        { path: 'dashboard/category-msi', element: <CategoryMSI /> },
        { path: 'dashboard/product-msi', element: <ProductMSI /> },
        { path: 'dashboard/product-fip', element: <ProductsFip /> },
        { path: 'dashboard/category-fip', element: <CategoryFip /> },
        { path: 'dashboard/sub-category-fip', element: <SubCategoryFip /> },
        { path: 'dashboard/SendNotification', element: <SendNotification /> },
      ],
    },
    {
      path: 'admin/login',
      element: isAuthenticated ? <Navigate to="/admin/dashboard/app" /> : <LoginPage />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/admin/dashboard/app" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
