import axios from 'axios';

import { API_URL } from '../../utils/constant';


export const deleteUsers = async (id) => {
    const categoryData = await axios.delete(`${API_URL}/users/${id}`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
        }
    })
    const category = categoryData.data;
    return category;
}
export const blockUser = async (id) => {
    const categoryData = await axios.put(`${API_URL}/users/block-user/${id}`, null, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
        }
    })
    const category = categoryData.data;
    return category;
}
export const updateUser = async (id, data) => {

    const categoryData = await axios.put(`${API_URL}/users/${id}`, data, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
        }
    })
    const category = categoryData.data;
    return category;
}



export const getUsersList = async (data) => {
    const { limit, page, keyword } = data;

    const categoryData = await axios.get(`${API_URL}/users/list?limit=${limit}&page=${page}&keyword=${keyword}`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
        }
    })

    const categoryList = categoryData.data;
    return categoryList;

}
export const getUsersListForDropDown = async (keyword) => {
    const categoryData = await axios.get(`${API_URL}/users/admin/list?keyword=${keyword}`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
        }
    })

    const categoryList = categoryData.data;
    return categoryList;

}