import axios from 'axios';

import { API_URL } from '../../utils/constant';

export const createsubCatgory = async (data) => {
  const subCatgoryData = await axios.post(`${API_URL}/sub-category`, data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });
  const subCatgory = subCatgoryData.data;
  return subCatgory;
};
export const updatesubCatgory = async (id, data) => {
  const subCatgoryData = await axios.put(`${API_URL}/sub-category/${id}`, data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });
  const subCatgory = subCatgoryData.data;
  return subCatgory;
};
export const deleteSubCategory = async (id) => {
  const subCatgoryData = await axios.delete(`${API_URL}/sub-category/${id}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });
  const subCatgory = subCatgoryData.data;
  return subCatgory;
};
export const getsubCatgoryById = async (id) => {
  const subCatgoryData = await axios.get(`${API_URL}/sub-category/${id}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });
  const subCatgory = subCatgoryData.data;
  return subCatgory;
};
export const getSubCatgoryList = async (data) => {
  const subCatgoryData = await axios.post(`${API_URL}/sub-category/list`, data, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });

  const subCatgoryList = subCatgoryData.data;
  return subCatgoryList;
  // return {
  //     data: {

  //         count: 1,
  //         subCategoryList: [{
  //             id: 1,
  //             name: 'test',
  //             description: 'test',
  //             imageUrl: "data:image/jpeg;base64,/9j/4AAQSkZJRgABAQEAYABgAAD/4TDKRXhpZgAATU0AKgAAAAgABAExAAIAAAALAAAQSodpAAQAAAABAAAQVoglAAQAAAABAAAgouocAAcAABAMAAAAPgAAAAAc6gAAABAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA",
  //             category: {
  //                 id: 1, name: 'categor7', description: 'categor7', imageUrl: 'http://localhost:3000/images/1.jpg'
  //             }
  //         }]
  //     }
  // }
};
