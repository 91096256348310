import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';

// @mui

import {
  Link,
  Stack,
  Select,
  FormHelperText,
  MenuItem,
  CircularProgress,
  InputLabel,
  IconButton,
  InputAdornment,
  TextField,
  Input,
  Checkbox,
  Box,
  Card,
  Typography,
  Modal,
} from '@mui/material';
import { styled } from '@mui/material/styles';

// utils
import { LoadingButton } from '@mui/lab';
import { API_URL, IMAGE_URL } from '../../../utils/constant';

import { uploadImage } from '../../../api/upload';

// components

// ----------------------------------------------------------------------
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  overflow: 'auto',
  maxHeight: '100%',
};

// ----------------------------------------------------------------------

// ShopProductCard.propTypes = {
//     product: PropTypes.object,
// };
const intialError = {
  isValid: false,
  errors: {},
};
export default function AddCategory({ open, onPopUpClose, loading, onSubmit, initialValues }) {
  const [formData, setFormData] = useState(() => initialValues);
  const [selectedFile, setSelectedFile] = useState(null);

  const [selectedFileSrc, setSelectedFileSrc] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [formError, setFormError] = useState({
    isValid: false,
    errors: {},
  });
  const onblurEffect = (event) => {
    validationFormData();
  };
  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const validationFormData = async () => {
    if (formData.name === '' || formData.name.length < 3) {
      const name = 'name';
      setFormError((prevFormData) => ({
        isValid: false,
        errors: {
          ...prevFormData.errors,
          [name]: 'Name is not correct',
        },
      }));
      if (formData.description === '') {
        formData.description = 'null';
      }
      return false;
    }

    setFormError({
      isValid: true,
      errors: {},
    });
    return true;
  };
  const handleClick = async (event) => {
    event.preventDefault();
    validationFormData();

    if (selectedFile) {
      const url = await uploadImage('category-msi', selectedFile);
      formData.imageUrl = `${IMAGE_URL}/${url.data}`;
    }

    setSelectedFileSrc(null);
    onSubmit(formData);
    return true;
  };
  const handleImageUpload = (event) => {
    event.preventDefault();
    const file = event.target.files[0];
    setSelectedFile(file);
    const reader = new FileReader();

    reader.onload = () => {
      const base64Image = reader.result;
      // const name = 'imageUrl'
      // Use the base64Image string as needed
      setSelectedFileSrc(base64Image);
      // setFormData((prevFormData) => ({
      //     ...prevFormData,
      //     [name]: base64Image,
      // }));
    };
    reader.readAsDataURL(file);
  };

  // const { open } = props;
  const handleClose = () => {
    onPopUpClose();
  };
  useEffect(() => {
    setFormData(initialValues);
    validationFormData();
  }, [initialValues]);
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <h2 style={{ marginLeft: '10px' }}>Add Category</h2>
          <IconButton onClick={handleClose}>X</IconButton>
        </Stack>
        <form id="loginForm" onSubmit={handleClick}>
          <Stack spacing={3}>
            <TextField
              name="name"
              label="Category Name"
              value={formData.name}
              onChange={handleFormChange}
              onBlur={onblurEffect}
              required
            />

            {!formError?.isValid && formError.errors.name && formError.errors.name !== '' ? (
              <FormHelperText error>{formError.errors.name}</FormHelperText>
            ) : null}
            {/* <TextField name="description" label="Description" value={formData.description} onChange={handleFormChange} /> */}
            <TextField type="file" name="imageUrl" accept="image/jpeg, image/png" onChange={handleImageUpload} />
            <FormHelperText dark>image must 500x500</FormHelperText>
            <img height={100} width={100} src={selectedFileSrc || formData.imageUrl} alt="" />

            <Stack alignItems="center" spacing={2}>
              {loading ? (
                <Stack alignItems="center" justifyContent="center">
                  <CircularProgress />
                </Stack>
              ) : (
                <LoadingButton disabled={!formError.isValid} fullWidth size="large" type="submit" variant="contained">
                  {formData.id !== '' ? 'Update Category' : 'Add Category'}
                </LoadingButton>
              )}
            </Stack>
          </Stack>
        </form>
      </Box>
    </Modal>
  );
}
