import axios from 'axios';

import { API_URL } from '../../utils/constant';

export const createProducts = async (data) => {
    const categoryData = await axios.post(`${API_URL}/products-msi`, data,
        {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        })
    const category = categoryData.data;
    return category;

}
export const updateProducts = async (id, data) => {
    const categoryData = await axios.put(`${API_URL}/products-msi/${id}`, data,
        {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        })
    const category = categoryData.data;
    return category;
}
export const deleteProducts = async (id) => {
    const categoryData = await axios.delete(`${API_URL}/products-msi/${id}`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
        }
    })
    const category = categoryData.data;
    return category;
}

export const getProductsList = async (data) => {
    const categoryData = await axios.post(`${API_URL}/products-msi/list`, data,{
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
        }
    })
    const categoryList = categoryData.data;
    return categoryList;

    // return {
    //     data: {
    //         count: 1,
    //         productList: [{
    //             id: 1,
    //             name: 'test',
    //             description: 'test',
    //             imageUrl: 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAHElEQVQI12P4//8/w38GIAXDIBKE0DHxgljNBAAO9TXL0Y4OHwAAAABJRU5ErkJggg==',
    //             category: {
    //                 id: 1, name: 'categor7', description: 'categor7', imageUrl: 'http://localhost:3000/images/1.jpg'
    //             }, subCategory: {
    //                 id: 1, name: 'subcategor7', description: 'categor7', imageUrl: 'http://localhost:3000/images/1.jpg'
    //             }, brand: {
    //                 id: 1, name: 'brand', description: 'categor7', imageUrl: 'http://localhost:3000/images/1.jpg'
    //             }
    //         }]
    //     }
    // }

}