import axios from 'axios';

import { API_URL } from '../../utils/constant';

export const uploadImage = async (type, file) => {
  const formData = new FormData();
  formData.append('file', file);

  const uploadImage = await axios.post(`${API_URL}/files/upload?type=${type}`, formData, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'Content-Type': 'multipart/form-data',
    },
  });
  const path = uploadImage.data;
  return path;
};

export const uploadExcel = async (file) => {
  const formData = new FormData();
  formData.append('file', file);

  const uploadExcel = await axios.post(`${API_URL}/products/bulk-insert`, formData, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'Content-Type': 'multipart/form-data',
    },
  });
  const res = uploadExcel.data;
  return res;
};

export const uploadExcelForFip = async (file) => {
  const formData = new FormData();
  formData.append('file', file);

  const uploadExcel = await axios.post(`${API_URL}/products-fip/bulk-insert`, formData, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
      'Content-Type': 'multipart/form-data',
    },
  });
  const res = uploadExcel.data;
  return res;
};
