// component
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'dashboard',
    path: '/admin/dashboard/app',
    icon: icon('ic_analytics'),
  },
  {
    title: '  Fip Brand',

    path: '/admin/dashboard/category-fip',
    icon: icon('ic_blog'),
  },
  {
    title: '  Fip Category',
    path: '/admin/dashboard/sub-category-fip',
    icon: icon('ic_blog'),
  },

  {
    title: ' Fip Products ',
    path: '/admin/dashboard/product-fip',
    icon: icon('ic_blog'),
  },

  {
    title: 'CRDI Brand',
    path: '/admin/dashboard/category',
    icon: icon('ic_cart'),
  },
  {
    title: 'CRDI Category ',
    path: '/admin/dashboard/subCategory',
    icon: icon('ic_blog'),
  },
  // {
  //   title: 'Brand',
  //   path: '/dashboard/brand',
  //   icon: icon('ic_cart'),
  // },
  {
    title: ' CRDI Products',
    path: '/admin/dashboard/product',
    icon: icon('ic_cart'),
  },

  {
    title: ' MSI Brand',
    path: '/admin/dashboard/category-msi',
    icon: icon('ic_user'),
  },
  {
    title: ' MSI Products',
    path: '/admin/dashboard/product-msi',
    icon: icon('ic_user'),
  },

  {
    title: 'user',
    path: '/admin/dashboard/user',
    icon: icon('ic_user'),
  },
  {
    title: 'Plans',
    path: '/admin/dashboard/plans',
    icon: icon('ic_blog'),
  },
  {
    title: 'membership',
    path: '/admin/dashboard/membership',
    icon: icon('ic_user'),
  },
  {
    title: 'send Notification',
    path: '/admin/dashboard/SendNotification',
    icon: icon('ic_cart'),
  },
  // {
  //   title: 'blog',
  //   path: '/dashboard/blog',
  //   icon: icon('ic_blog'),
  // },

  // {
  //   title: 'login',
  //   path: '/login',
  //   icon: icon('ic_lock'),
  // },
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: icon('ic_disabled'),
  // },
];

export default navConfig;
