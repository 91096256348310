


//    -----------------local url-------------------

// export const API_URL = 'http://localhost:3002/fuel-engine';
// export const IMAGE_URL = 'http://localhost:3001/backend/public';



// // ----------------------- server url----------------
export const API_URL = 'http://dieselduniya.com:3001/fuel-engine';
export const IMAGE_URL = 'http://dieselduniya.com/backend/public';




// // ----------------------- staging server url----------------
// export const API_URL = 'http://staging.dieselduniya.com:3000/fuel-engine';
// export const IMAGE_URL = 'http://staging.dieselduniya.com/backend/public';