import axios from 'axios';

import { API_URL } from '../../utils/constant';

export const createPlan = async (data) => {
    const PlanData = await axios.post(`${API_URL}/plans`, data,
        {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        })
    const Plan = PlanData.data;
    return Plan;

}
export const updatePlan = async (id, data) => {
    const PlanData = await axios.put(`${API_URL}/plans/${id}`, data,
        {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        })
    const Plan = PlanData.data;
    return Plan;
}
export const deletePlan = async (id) => {
    const PlanData = await axios.delete(`${API_URL}/plans/${id}`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
        }
    })
    const Plan = PlanData.data;
    return Plan;
}
export const getPlanById = async (id) => {
    const PlanData = await axios.get(`${API_URL}/plans/${id}`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
        }
    })
    const Plan = PlanData.data;
    return Plan;
}
export const getPlanList = async (data) => {
    const { limit, page, keyword } = data;
    const PlanData = await axios.get(`${API_URL}/plans/list?limit=${limit}&page=${page}&keyword=${keyword}`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
        }
    })
    const PlanList = PlanData.data;
    return PlanList;

}