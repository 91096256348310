import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { useState, useEffect } from 'react';

// @mui
import {
    Card,
    Table,
    Stack,
    Paper,
    Avatar,
    Button,
    Popover,
    Checkbox,
    TableRow,
    MenuItem,
    TableBody,
    TableCell,
    Container,
    Typography,
    IconButton,
    TableContainer,
    TablePagination,
} from '@mui/material';
import { API_URL } from '../utils/constant';

// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { getBrandList } from '../api/brand';
import { AddCategory, CategoryListHead, CategoryListToolBar } from '../sections/@dashboard/category';

// mock
import USERLIST from '../_mock/user';
import { category } from '../_mock/category';
import { getCategoryList, updateCategory, createCategory, deleteCategoy } from '../api/category';
import {ChangePasswordForm} from '../sections/@dashboard/user';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'name', label: 'Name', alignRight: false },
    { id: 'description', label: 'Description', alignRight: false },
    { id: 'imageurl', label: 'Image', alignRight: false },

    { id: '' },
];


// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}
const initialValues = {
    id: '',
    name: '',
    description: '',
    imageUrl: ''
}
export default function ChangePassword() {
    const [open, setOpen] = useState(null);
    const [openAddPopUp, setAddPopUp] = useState(false);
    const [isAddProductLoading, setIsAddProductLoading] = useState(false);

    const [total, setTotal] = useState(0);
    const [categoryList, setCategoryList] = useState([]);
    const [page, setPage] = useState(0);

    const [order, setOrder] = useState('asc');

    const [selected, setSelected] = useState([]);

    const [orderBy, setOrderBy] = useState('name');

    const [filterName, setFilterName] = useState('');

    const [rowsPerPage, setRowsPerPage] = useState(5);

    const [categoryToBeUpdated, setCategoryToBeUpdated] = useState({
        id: '',
        name: '',
        description: '',
        imageUrl: ''
    });

    const handleOpenMenu = (event, id) => {
        const category = categoryList.find(brand => brand.id === id)
        setCategoryToBeUpdated(category)
        setOpen(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setOpen(null);
    };
    const handleSubmit = async (data) => {
        try {
            setIsAddProductLoading(true);
            if (data.id !== '') {
                await updateCategory(data.id, data)
                // const index = category.categoryList.findIndex(category => category.id === data.id)
                // category.categoryList[index] = data;

            } else {
                delete data.id
                await createCategory(data)
                // data.id = category.categoryList.length + 1;
                // data.imageurl = null;
                // category.categoryList.push(data);
                // category.count = category.categoryList.length
              
            }
            setCategoryToBeUpdated({
                id: '',
                name: '',
                description: '',
                imageUrl: ''
            })
            await getCategoryListData()
            setIsAddProductLoading(false);
            setAddPopUp(false);
            setOpen(null);
        } catch (error) {
            setIsAddProductLoading(false);
            if(error?.response.status ===401){
                localStorage.clear();
                window.location.reload();
            }

            alert(error?.response?.data?.error || 'Something went wrong')

     
        }
    };

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = USERLIST.map((n) => n.name);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    };

    const handleChangePage = async (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = async (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
   


    };

    const handleFilterByName = (event) => {
        setPage(0);
        setFilterName(event.target.value);
    };
    const handleDelete = async (event) => {
        if (categoryToBeUpdated.id !== '') {
            await deleteCategoy(categoryToBeUpdated.id);
        }
        await getCategoryListData();
        setOpen(null);



    };
    const getCategoryListData = async () => {
        try {
            const data = {
                page: page + 1,
                limit: rowsPerPage,
                keyword: filterName
            }
            const categoryRes = await getCategoryList(data);
            const { categoryList, count } = categoryRes.data
        
            setTotal(count);
            setCategoryList(categoryList)
        } catch (error) {
            if(error?.response.status ===401){
                localStorage.clear();
                window.location.reload();
            }
            alert(error?.response?.data?.error || 'Something went wrong')

        
        }
    };
    useEffect(() => {
        getCategoryListData();

    }, [page, rowsPerPage, filterName])

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

    // const filteredUsers = applySortFilter(USERLIST, getComparator(order, orderBy), filterName);

    const isNotFound = !categoryList.length && !!filterName;

    return (
        <>
            <Helmet>
                <title> Change Password </title>
            </Helmet>

            <Container>
      
                <ChangePasswordForm />

                
            </Container>


        </>
    );
}
